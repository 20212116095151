@font-face {
  font-family: 'TT Commons';
  font-style: 'normal';
  font-weight: 400;
  src: url('./style/fonts/TTCommons-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'TT Commons';
  font-style: 'normal';
  font-weight: 500;
  src: url('./style/fonts/TTCommons-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'TT Commons';
  font-style: 'normal';
  font-weight: 700;
  src: url('./style/fonts/TTCommons-Bold.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'TT Commons', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
